/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');
var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');

var GetInternalCompletedApplicationsByEntity = require('../services/GetInternalCompletedApplicationsByEntity');
var GetEntityFlaggedStatus = require('../services/GetEntityFlaggedStatus');

var NonDepEntityInternalCompletedApplicationView = require('../views/NonDepEntityInternalCompletedApplicationView');

module.exports = function(entityId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(
    GetEntityIdentifiers(entityId),
    GetInternalCompletedApplicationsByEntity(entityId),
    GetEntityFlaggedStatus(entityId)
  )
    .done(function(entityIdentifier, completedApplications, hasFlagged) {
      NavUtils.navigate('nondep/entity/' + entityId + '/completed-applications');

      Scaffold.content.show(
        new NonDepEntityInternalCompletedApplicationView({
          model: new Backbone.Model(
            _.extend(entityIdentifier, { hasFlagged: hasFlagged }, { completedApplications: completedApplications })
          )
        })
      );
      NProgress.done();
    })
    .fail(function(err) {
      NProgress.done();
    });
};
