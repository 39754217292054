/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var _ = require('underscore');

var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

var parsleyUtil = require('common/util/parsleyUtil');
var makeUploadCollection = require('common/util/makeUploadCollection');
var modelGet = require('common/util/modelGet');

var CommentsDocumentView = require('common/views/CommentsDocumentView');

var tmplDepBankArticlesAmendmentPubReqsSectionView = require('./DepBankArticlesAmendmentPubReqsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepBankArticlesAmendmentPubReqsSectionView,

  className: 'dep-bank-articles-amendment-pub-reqs-section-view',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    documentForm: '.document-form',
    publicationRequirementsContainer: '.publication-requirements-container',
    generalPublicationProofsContainer: '.general-publication-proofs-container',
    legalPublicationProofsContainer: '.legal-publication-proofs-container'
  },

  regions: {
    publicationRequirementsContainer: '@ui.publicationRequirementsContainer',
    generalPublicationProofsContainer: '@ui.generalPublicationProofsContainer',
    legalPublicationProofsContainer: '@ui.legalPublicationProofsContainer'
  },

  templateHelpers: function() {
    // 102 for bank amendment and 301 for trust amendment
    var filingTypeId = modelGet(this.model, 'filingDetails.filingTypeId');
    var isTrust = filingTypeId === '301';

    return {
      isReadOnly: this.options.isReadOnly,
      isTrust: isTrust
    };
  },

  onBeforeShow: function() {
    var filingContent = this.model.get('filingContent') || {};
    var filingTypeId = modelGet(this.model, 'filingDetails.filingTypeId');
    var isTrust = filingTypeId === '301';
    var publicationRequirements = filingContent.publicationReqs || {};
    var generalPublicationProofDocs = filingContent.generalPublicationProofDocs || [];
    var legalPublicationProofDocs = filingContent.legalPublicationProofDocs || [];

    Syphon.deserialize(this, this.model.get('filingContent'));

    if (isTrust) {
      this.generalPublicationProofs = new CommentsDocumentView({
        isReadOnly: this.options.isReadOnly,
        allowMultipleFiles: true,
        isRequired: true,
        fileUploadLabel: 'Section II - Newspaper of General Circulation Proof of Publication',
        commentsLabel: 'Section II - Newspaper of General Circulation Proof of Publication',
        commentsName: 'generalPublicationProofComments',
        collection: new FileUploadCollection(generalPublicationProofDocs),
        model: new Backbone.Model({ generalPublicationProofComments: filingContent.generalPublicationProofComments }),
        documentTypeId: 20001
      });
      this.showChildView('generalPublicationProofsContainer', this.generalPublicationProofs);

      this.legalPublicationProofs = new CommentsDocumentView({
        isReadOnly: this.options.isReadOnly,
        allowMultipleFiles: true,
        isRequired: true,
        fileUploadLabel: 'Section II - Legal Newspaper Proof of Publication',
        commentsLabel: 'Section II - Legal Newspaper Proof of Publication',
        commentsName: 'legalPublicationProofComments',
        collection: new FileUploadCollection(legalPublicationProofDocs),
        model: new Backbone.Model({ legalPublicationProofComments: filingContent.legalPublicationProofComments }),
        documentTypeId: 20001
      });
      this.showChildView('legalPublicationProofsContainer', this.legalPublicationProofs);
    } else {
      this.publicationRequirements = new CommentsDocumentView({
        isReadOnly: this.options.isReadOnly,
        allowMultipleFiles: false,
        isRequired: true,
        fileUploadLabel: 'Section II - Publication Requirements',
        commentsLabel: 'Section II - Publication Requirements Comments',
        commentsName: 'publicationReqsComments',
        collection: makeUploadCollection(publicationRequirements.file),
        model: new Backbone.Model({ publicationReqsComments: publicationRequirements.comments }),
        documentTypeId: 20001
      });
      this.showChildView('publicationRequirementsContainer', this.publicationRequirements);
    }
  },

  validate: function(config) {
    var filingTypeId = modelGet(this.model, 'filingDetails.filingTypeId');
    var isTrust = filingTypeId === '301';
    this.ui.documentForm.parsley().reset();
    if (isTrust) {
      this.generalPublicationProofs.removeValidationErrors();
      this.legalPublicationProofs.removeValidationErrors();
    } else {
      this.publicationRequirements.removeValidationErrors();
    }

    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.documentForm);
    } else {
      var validationPromises = [this.ui.documentForm.parsley().whenValidate()];
      if (isTrust) {
        validationPromises.push(this.generalPublicationProofs.validate());
        validationPromises.push(this.legalPublicationProofs.validate());
      } else {
        validationPromises.push(this.publicationRequirements.validate());
      }

      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.documentForm);
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    var filingTypeId = modelGet(this.model, 'filingDetails.filingTypeId');
    var isTrust = filingTypeId === '301';
    formData.publicationReqsDocId = isTrust ? null : this.publicationRequirements.getFirstFileId();
    if (isTrust) {
      formData.generalPublicationProofDocIds = _.pluck(
        this.generalPublicationProofs.fileUploadView.collection.toJSON(),
        'fileId'
      );
      formData.legalPublicationProofDocIds = _.pluck(
        this.legalPublicationProofs.fileUploadView.collection.toJSON(),
        'fileId'
      );
    }

    return formData;
  }
});
