/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetEntityLocationsAndLicenses: {
        $xmlns: 'http://dobs.pa.gov/nd/entity/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: [
      'GetEntityLocationsAndLicensesResponse.Locations.ND_Location',
      'GetEntityLocationsAndLicensesResponse.Licenses.ND_License'
    ],
    blueprint: {
      locations: [
        '$..ND_Location',
        {
          id: '$.ID',
          isPrimaryPlaceOfBusiness: '$.IsPrimaryPlaceOfBusiness',
          officePhoneNumber: '$.OfficePhoneNumber',
          officeFaxNumber: '$.OfficeFaxNumber',
          createdDate: '$.CreatedDate',
          address: {
            id: '$.AddressID',
            addressLine1: '$.AddressLine1',
            addressLine2: '$.AddressLine2',
            addressLine3: '$.AddressLine3',
            city: '$.City',
            state: '$..State',
            postalCode: '$.PostalCode',
            country: '$.Country',
            county: '$.County'
          }
        }
      ],
      licenses: [
        '$..ND_License',
        {
          id: '$.ID',
          licenseNumber: '$.LicenseNumber',
          locationID: '$.LocationID',
          licenseStatus: '$.LicenseStatus',
          licenseType: '$.LicenseType',
          licenseStatusId: '$.LicenseStatusID'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.licenses, function(license) {
        license.entityId = entityId;
      });

      _.each(response.locations, function(location) {
        location.officePhoneNumber = location.officePhoneNumber || '--';
        location.officeFaxNumber = location.officeFaxNumber || '--';
        location.isPrimaryPlaceOfBusiness = location.isPrimaryPlaceOfBusiness === '1';
        location.createdDate = moment(location.createdDate, moment.ISO_8601).format('MM/DD/YYYY hh:mm a');
        location.licenses = _.where(response.licenses, { locationID: location.id });
      });

      // list of active license statuses
      var ACTIVE_LICENSE_STATUSES = [
        '1', // Approved
        '2', // Approved Deficient
        '5', // Pending Deficient
        '7', // Pending Incomplete
        '11', // Suspended
        '12', // Terminated Failed to Renew
        '16' // Approved Inactive
      ];

      var sortedLocations = _.chain(response.locations)
        .sortBy(function(location) {
          // Sort by the number of licenses (descending)
          return -location.licenses.length;
        })
        .sortBy(function(location) {
          // Sort by the number of active licenses (descending)
          return -_.filter(location.licenses, function(license) {
            return ACTIVE_LICENSE_STATUSES.includes(license.licenseStatusId);
          }).length;
        })
        .sortBy(function(location) {
          // Sort by primary place of business (descending)
          return -location.isPrimaryPlaceOfBusiness;
        })
        .value();

      delete response.licenses;
      deferred.resolve(sortedLocations);
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
};
